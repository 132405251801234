import { render, staticRenderFns } from "./Goods.vue?vue&type=template&id=4b7a53cc&scoped=true"
import script from "./Goods.vue?vue&type=script&lang=js"
export * from "./Goods.vue?vue&type=script&lang=js"
import style0 from "./Goods.vue?vue&type=style&index=0&id=4b7a53cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b7a53cc",
  null
  
)

export default component.exports